<template>
  <q-input
    :label="label + ':'"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"

    filled
    class="col-xs-12 col-sm-6 col-md-4"

    :rules="rules"
    :error="error"
    :error-message="errorMessage"
  >
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
    <template v-slot:prepend>
      <slot name="prepend"></slot>
    </template>
  </q-input>
</template>

<script>
export default {
  name: 'Input',
  props: {
    modelValue: {},
    label: {
      type: String,
    },
    rules: {
      type: Array,
    },
    error: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
    },
  }
}
</script>
