<template>
  <q-btn
    dense
    flat
    color="primary"
    icon="visibility"
  >
    <q-tooltip class="bg-accent">
      <slot />
    </q-tooltip>
  </q-btn>
</template>

<script>
export default {
  name: 'ActShow',
}
</script>
