<template>
  <q-dialog
    :modelValue="modalUpdating.isOpen"
    @update:modelValue="modalUpdating.isOpen = $event"
  >
    <q-card style="width: 900px; max-width: 90vw;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Редактирование клиента</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-form @submit="update">
        <q-card-section>
          <div class="row q-col-gutter-x-md">
            <slot />
            <input-name v-model="modalUpdating.data.name"/>
            <input-phone v-model="modalUpdating.data.phone" />
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <btn-close />
          <btn-save />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
// import History from "@/components/History";
// import Files from "@/components/Files";
import {mapState, mapActions} from "vuex";

export default {
  // components: {
  //   History,
  //   Files,
  // },
  computed: mapState({
    'modalUpdating': state => state.clients.modals.updating,
  }),

  methods: {
    ...mapActions('clients', ['update']),
  },
}
</script>
