import * as clientsApi from '@/api/clients'
import Notify from 'quasar/src/plugins/Notify.js';
export default {
  namespaced: true,
  state: {
    table: {
      data: [],
      filter: {},
      pagination: {
        page: 1,
        rowsPerPage: 15,
        rowsNumber: 0
      },
      loading: false,
    },
    modals: {
      creating: {
        isOpen: false,
        data: {}
      },
      updating: {
        isOpen: false,
        data: {}
      },
      removing: {
        isOpen: false,
        data: {}
      },
    },
  },
  getters: {
    // all: state => state.items,
    // one: state => id => state.items.find(item => item.id == id),
    // в компоненте {{ $store.getters['clients/one']($route.params.id) }}
  },
  mutations: {

  },
  actions: {
    openModalCreating({state}) {
      state.modals.creating.isOpen = true
    },

    openModalUpdating({state}, client) { //TODO значение не должно меняться в таблице
      console.log('feee')
      state.modals.updating.isOpen = true
      state.modals.updating.data = client
    },

    openModalRemoving({state}, client) {
      state.modals.removing.isOpen = true
      state.modals.removing.data = client
    },
    // changePagination({state}, pagination) {
    //   state.table.pagination = pagination
    // },
    async getAll({state}, { pagination, filter }) {
      state.table.loading = true

      let res = await clientsApi.getAll({
        page: pagination.page,
        filter: filter,
      })

      state.table.data = res.data

      state.table.pagination.page = res.current_page
      state.table.pagination.rowsPerPage = res.per_page
      state.table.pagination.rowsNumber = res.total

      state.table.loading = false

      // commit('set', { cart, token });
      console.log(res)
    },

    async create({state}) {
      let createdClient = await clientsApi.create(state.modals.creating.data)

      state.table.data.unshift(createdClient)

      state.modals.creating.isOpen = false
      state.modals.creating.data = {}

      Notify.create({
        message: `Клиент ${createdClient.name} создан`,
        color: 'purple'
      })
    },

    async update({state}) {
      let updatedClient = await clientsApi.update(state.modals.updating.data)

      if (updatedClient !== '') {
        state.table.data.map(c => {
          if (c.id === updatedClient.id) {
            for (let key in c) {
              c[key] = updatedClient[key];
            }
          }
        })
      }

      state.modals.updating.isOpen = false
      state.modals.updating.data = {}

      Notify.create({
        message: `Клиент ${updatedClient.name} изменен`,
        color: 'purple'
      })
    },

    async remove({state}) {
      let removedClient = await clientsApi.remove(state.modals.removing.data.id)

      // state.table.data.splice(state.modals.removing.data.index, 1)
      state.table.data = state.table.data.filter(client => client.id !== removedClient.id)

      state.modals.removing.isOpen = false
      state.modals.removing.data = {}

      Notify.create({
        message: `Клиент ${removedClient.name} удален`,
        color: 'purple'
      })
    },

    // watch: {
    //   createbleDeal(contract) {
    //     if (contract !== '') {
    //       console.log('dfdfdf');
    //       this.contracts.unshift(contract)
    //       this.$emit('update:createbleDeal', '')
    //     }
    //   },
    //   // createbleClient(client) {
    //   //   if (client !== '') {
    //   //     this.$emit('update:createbleClient', '')
    //   //   }
    //   // },
    //   updatebleDeal(contract) {
    //     if (contract !== '') {
    //       this.contracts.map(c => {
    //         if (c.id === contract.id) {
    //           for (let key in c) {
    //             c[key] = contract[key];
    //           }
    //         }
    //       })
    //
    //       this.$emit('update:updatebleDeal', '')
    //     }
    //   },
    //   updatebleClient(client) {
    //     if (client !== '') {
    //       this.contracts.map(contract => {
    //         if (contract.client_id === client.id) {
    //           for (let key in client) {
    //             contract.client[key] = client[key];
    //           }
    //         }
    //       })
    //
    //       this.$emit('update:updatebleClient', '')
    //     }
    //   },
    // },

    // async add({ state, getters, commit }, { id }){
    //   if(getters.canAdd(id)){
    //     commit('startProccess', id);
    //     let { res, data } = await cartApi.add(state.token, id)
    //
    //     if(res && data){
    //       commit('add', { id });
    //     }
    //
    //     commit('endProccess', id);
    //   }
    // },
  },
}
