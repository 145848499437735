import http from '@/services/http';

export async function getAll({page, filter}){
  let { data } = await http.get('/payments',{params: {page: page, ...filter}});
  return data;
}

export async function getOne(id){
  let { data } = await http.get(`/payments/${id}`);
  return data;
}

export async function create(item){
  let { data } = await http.post('/payments', item);
  return data;
}

export async function update(item){
  let { data } = await http.put(`/payments/${item.id}`, item);
  return data;
}

export async function remove(id){
  let { data } = await http.delete(`/payments/${id}`);
  return data;
}

