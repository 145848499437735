<template>
  <q-dialog
    :modelValue="modalRemoving.isOpen"
    @update:modelValue="modalRemoving.isOpen = $event"
  >
    <q-card>
      <q-card-section class="row items-center bg-primary text-white">
        <!--          <q-avatar icon="warning" text-color="warning" />-->
        <q-avatar icon="warning" />
        <span class="q-ml-sm">
          Удалить клиента?
        </span>
      </q-card-section>

      <q-card-actions align="right">
        <btn-close />
        <btn-continue @click="remove" />
      </q-card-actions>
    </q-card>

    <!--      <q-card>-->
    <!--        <q-card-section class="row items-center">-->
    <!--          <q-avatar icon="warning" color="warning" text-color="white" />-->
    <!--          <span class="q-ml-sm">Вы хотите удалить платеж?</span>-->
    <!--        </q-card-section>-->

    <!--        <q-card-actions align="right">-->
    <!--          <q-btn flat label="Закрыть" color="primary" v-close-popup />-->
    <!--&lt;!&ndash;          <q-btn outline label="Продолжить" color="secondary" v-close-popup />&ndash;&gt;-->
    <!--          <q-btn flat label="Продолжить" color="secondary" class="bg-teal-1" v-close-popup />-->
    <!--        </q-card-actions>-->
    <!--      </q-card>-->
  </q-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  computed: mapState({
    'modalRemoving': state => state.clients.modals.removing,
  }),

  methods: mapActions('clients', ['remove']),
}
</script>
