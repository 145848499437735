<template>
  <Input
    label="Сумма"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"

    :rules="rules"
  />
</template>

<script>
import Input from '@/components/ui/Input'

export default {
  name: 'InputSum',
  components: {
    Input
  },
  props: {
    modelValue: {},
    rules: {
      type: Array,
      default: []
    },
  }
}
</script>
