<template>
  <q-btn
    label="Сохранить"
    type="submit"
    color="secondary"
    flat
  />
</template>

<script>
export default {
  name: 'BtnSave',
}
</script>
