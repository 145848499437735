<template>
  <Select
    label="Представитель"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    :options="this.$store.state.lists.agents"
    optionValue="user_id"

    :rules="rules"
  />
</template>

<script>
import Select from '@/components/ui/Select'

export default {
  name: 'SelectAgent',
  components: {
    Select
  },
  props: {
    modelValue: {
      // type: Number,
      // required: true,
    },
    rules: {
      type: Array,
      // default: [ val => val && val.length > 0 || 'Выберите представителя']
      default: []
    },
  },
}
</script>
