<template>
  <q-dialog
    :modelValue="modalUpdating.isOpen"
    @update:modelValue="modalUpdating.isOpen = $event"
  >
    <q-card style="width: 900px; max-width: 90vw;">
    <q-card-section class="row items-center q-pb-none">
      <div class="text-h6">Редактирование дела</div>
      <q-space />
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>
    <q-form @submit="update">
      <q-card-section>
        <div class="row q-col-gutter-x-md">
          <select-stage v-model="modalUpdating.data.stage_id" />
          <select-subject v-model="modalUpdating.data.subject_id" />
          <select-theme v-model="modalUpdating.data.theme_id" />
          <select-completed v-model="modalUpdating.data.completed_id" />
          <select-actresult v-model="modalUpdating.data.act_result_id" />
          <select-appeal v-model="modalUpdating.data.appeal_id" />
          <select-status v-model="modalUpdating.data.status_id" />
          <select-result v-model="modalUpdating.data.result_id" />
          <!--    <text-comment v-model="modalUpdating.data.comment" />-->
          <slot />
        </div>
      </q-card-section>
      <q-card-actions align="right">
        <btn-save />
      </q-card-actions>
    </q-form>
  </q-card>
  </q-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  computed: mapState({
    'modalUpdating': state => state.deals.modals.updating,
  }),

  methods: {
    ...mapActions('deals', ['update']),
  },
}
</script>
