<template>
  <Select
    :label="'Зам' + second"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    :options="this.$store.state.lists.submanagers"
    optionValue="user_id"

    :rules="rules"
  />
</template>

<script>
import Select from '@/components/ui/Select'

export default {
  name: 'SelectSubmanager',
  components: {
    Select
  },
  props: {
    modelValue: {
      // type: Number,
      // required: true,
    },
    isSecond: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: []
    },
  },
  computed: {
    second() {
      if (this.isSecond) {
        return ' 2'
      }
      return ''
    }
  },
}
</script>
