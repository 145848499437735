import http from '@/services/http';

export async function getAll({page, filter}){
  let { data } = await http.get('/submanagers',{params: {page: page, ...filter}});
  return data;
}

export async function getOne(id){
  let { data } = await http.get(`/submanagers/${id}`);
  return data;
}

export async function create(item){
  let { data } = await http.post('/submanagers', item);
  return data;
}

export async function update(item){
  let { data } = await http.put(`/submanagers/${item.user_id}`, item);
  return data;
}

export async function remove(id){
  let { data } = await http.delete(`/submanagers/${id}`);
  return data;
}

