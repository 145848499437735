import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import http  from '@/services/http'
import uiComponents from '@/components/ui'

window.http = http

const app = createApp(App);

uiComponents.forEach( uiComponent => {
  app.component(uiComponent.name, uiComponent)
})

app
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  // .mount('#app')


// store.dispatch('user/autoLogin');// получаем юзера из токена даже после перезагрузки
// store.dispatch('cart/load');
// store.dispatch('products/load').then(() => {
//   app.mount('#app');
// });


// store.dispatch('loadStore').then(() => {
//   app.mount('#app');
// });

// app.mount('#app')

store.dispatch('user/autoLogin').then(() => {
  console.log(1)
  if (store.getters['user/isLogin']) {
    console.log(2)
    store.dispatch('loadStore')
  }
})

app.mount('#app')

// store.dispatch('user/autoLogin')
//   .then(() => {
//     if (store.getters['user/isLogin']) {
//       console.log('1111111111')
//       store.dispatch('loadStore')
//     }
//   }).then(() => {
//     app.mount('#app')
//   })
