<template>
  <Select
    label="Что сделано"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    :options="this.$store.state.lists.completeds"

    :rules="rules"
  />
</template>

<script>
import Select from '@/components/ui/Select'

export default {
  name: 'SelectCompleted',
  components: {
    Select
  },
  props: {
    modelValue: {},
    rules: {
      type: Array,
      default: []
    },
  },
}
</script>
