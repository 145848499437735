<template>
  <q-btn
    flat
    label="Закрыть"
    color="primary"
    v-close-popup
  />

<!--  <q-btn unelevated label="Продолжить" color="secondary" v-close-popup />-->
<!--  <q-btn outline label="Продолжить" color="secondary" v-close-popup />-->
<!--  <q-btn-->
<!--    flat-->
<!--    label="Продолжить"-->
<!--    color="positive"-->
<!--    class="bg-green-1"-->
<!--    v-close-popup-->
<!--    @click="deletePayment"-->
<!--  />-->
</template>

<script>
export default {
  name: 'BtnClose',
}
</script>
