<template>
  <q-layout view="lHh Lpr lFf" class="bg-indigo-1">

<!--    <q-header reveal class="bg-deep-purple text-white glossy" height-hint="98">-->
    <q-header reveal class="bg-blue-grey-10 glossy">
<!--    <q-header elevated>-->
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

<!--        <q-tabs align="left">-->
<!--          <q-route-tab to="/submanager/index" label="Дела" />-->
<!--          <q-route-tab to="/submanager/calendar" label="Календарь" />-->
<!--          <q-route-tab to="/submanager/jurists" label="Юристы" />-->
<!--        </q-tabs>-->

        <q-space />

        <q-btn
          flat
          round
          dense
          icon="person"
          class="q-mr-xs"
        />
        <q-btn
          flat
          round
          dense
          icon="logout"
          @click="logout"
        />

<!--        <q-btn flat round dense icon="more_vert">-->
<!--          <q-menu-->
<!--            transition-show="jump-down"-->
<!--            transition-hide="jump-up"-->
<!--          >-->
<!--            <q-list style="min-width: 100px">-->
<!--              <q-item clickable v-ripple>-->
<!--                <q-item-section avatar>-->
<!--                  <q-icon name="manage_accounts" />-->
<!--                </q-item-section>-->
<!--                <q-item-section>Профиль</q-item-section>-->
<!--              </q-item>-->
<!--              &lt;!&ndash; <q-item clickable>-->
<!--                <q-item-section avatar>-->
<!--                  <q-icon name="settings" />-->
<!--                </q-item-section>-->
<!--                <q-item-section>Настройки</q-item-section>-->
<!--              </q-item> &ndash;&gt;-->
<!--              <q-separator />-->
<!--              <q-item-->
<!--                clickable-->
<!--                @click="logout"-->
<!--              >-->
<!--                <q-item-section avatar>-->
<!--                  <q-icon name="logout" />-->
<!--                </q-item-section>-->
<!--                <q-item-section>Выйти</q-item-section>-->
<!--              </q-item>-->
<!--            </q-list>-->
<!--          </q-menu>-->
<!--        </q-btn>-->
      </q-toolbar>
    </q-header>

    <q-drawer
      show-if-above
      v-model="leftDrawerOpen"
      :width="250"
      class="shadow-4"
    >
      <q-scroll-area class="fit">
<!--        <q-date v-model="date"-->
<!--          mask="YYYY-MM-DD"-->
<!--          color="deep-purple"-->
<!--        />-->


        <q-date v-if="calendarType === 'meets'"
          :modelValue="date"
          @update:modelValue=changeDate
          @navigation="changeMonth"

          mask="YYYY-MM-DD"
          class="drawer-calendar"
          minimal
          today-btn
          flat
          square
        />

        <q-date v-else
          :modelValue="date"
          @update:modelValue=changeDate
          @navigation="changeMonth"
          range

          mask="YYYY-MM-DD"
          class="drawer-calendar"
          minimal
          today-btn
          flat
          square
        />

        <q-list bordered class="rounded-borders">
          <!-- <q-expansion-item
            v-if="menuItem.children"
            expand-separator
            content-inset-level="1"
            icon="mail"
            label="Inbox"
          >
          </q-expansion-item> -->


          <template v-for="(menuItem, index) in menuList" :key="index">
            <q-item
              clickable
              v-ripple
              :to="menuItem.link"
              :active="menuItem.label === activeMenuItem"
              @click="activeMenuItem = menuItem.label"
            >
              <q-item-section avatar>
                <q-icon :name="menuItem.icon" />
              </q-item-section>
              <q-item-section>
                {{ menuItem.label }}
              </q-item-section>
            </q-item>
            <q-separator :key="'sep' + index" v-if="menuItem.separator" />
          </template>
        </q-list>
      </q-scroll-area>
    </q-drawer>

    <q-page-container>
<!--      <q-page padding>-->
      <div v-if="$store.state.listsLoaded" class="q-pa-md">
        <router-view></router-view>
      </div>
<!--      </q-page>-->
    </q-page-container>

  </q-layout>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {date} from "quasar";

export default {
  name: 'CabinetLayout',
  props: [
    'leftDrawer'
  ],
  data() {
    return {
      leftDrawerOpen: this.leftDrawer.isOpen,
      activeMenuItem: this.leftDrawer.activeMenuItem,
      menuList: this.leftDrawer.menu,
    }
  },
  // created() {
  //   this.loadStore()
  // },

  computed: mapState({
    'date': state => state.minicalendar.selectedDate,
    'calendarType': state => state.minicalendar.type,
  }),

  methods: {
    ...mapActions('minicalendar', ['changeDate']),
    ...mapActions('meets', [
      'getEvents',
      'getResources',
    ]),
    // ...mapActions(['loadStore']),

    changeMonth(val) { // запрашиваем события для календаря если тип календаря meets
      if (this.calendarType === 'meets') {
        this.getEvents(date.formatDate( val.year + '-' + val.month + '-' + '1', 'YYYY-MM-DD'))
      }
    },

    logout() {
      this.$store.dispatch("user/logout")

      // this.$store.dispatch("user/logout").then(() => {
      //   this.$router.push({
      //     name: "login",
      //   });
      // });
    },
  },
}
</script>

<style lang="scss">
  .drawer-calendar {
    .q-date__view {
      //height: 100%;
      width: 84%;
      min-height: 0;
      padding: 10px;
    }
    .q-date__calendar-days-container {
      //height: 75%;
      min-height: 0;
    }
  }
  // убираем лицензию на календаре
  .fc-license-message {
    z-index: -1!important;
  }
</style>
