<template>
  <LayoutCabinet
    :leftDrawer="leftDrawer"
  />

  <ModalDealCreating>
    <select-submanager v-model="createbleDeal.submanager2_id" isSecond />
    <select-jurist v-model="createbleDeal.jurist_id" />
    <select-jurist v-model="createbleDeal.jurist2_id" isSecond />
    <select-agent v-model="createbleDeal.agent_id" />
  </ModalDealCreating>
  <ModalDealUpdating>
    <select-submanager v-model="updatebleDeal.submanager2_id" isSecond />
    <select-jurist v-model="updatebleDeal.jurist_id" />
    <select-jurist v-model="updatebleDeal.jurist2_id" isSecond />
    <select-agent v-model="updatebleDeal.agent_id" />
  </ModalDealUpdating>
  <ModalDealRemoving />

  <ModalContractCreating />
  <ModalContractUpdating />
  <ModalContractRemoving />

  <ModalClientCreating />
  <ModalClientUpdating />
  <ModalClientRemoving />

  <ModalSubmanagerCreating />
  <ModalSubmanagerShowing />
  <ModalSubmanagerUpdating />
  <ModalSubmanagerRemoving />

  <ModalJuristCreating>
    <select-submanager v-model="createbleJurist.submanager_id"/>
  </ModalJuristCreating>
  <ModalJuristShowing />
  <ModalJuristUpdating>
    <select-submanager v-model="updatebleJurist.submanager_id"/>
  </ModalJuristUpdating>
  <ModalJuristRemoving />

  <ModalAgentCreating />
  <ModalAgentShowing />
  <ModalAgentUpdating />
  <ModalAgentRemoving />

  <!--  <ModalCreatePayment>-->

  <!--  </ModalCreatePayment>-->
  <!--  <ModalUpdatePayment>-->

  <!--  </ModalUpdatePayment>-->

  <!--  <ModalCreateMeet>-->

  <!--  </ModalCreateMeet>-->
  <!--  <ModalUpdateMeet>-->

  <!--  </ModalUpdateMeet>-->


</template>

<script>
import LayoutCabinet from "@/components/LayoutCabinet";
import ModalDealCreating from "@/components/deal/ModalDealCreating";
import ModalDealUpdating from "@/components/deal/ModalDealUpdating";
import ModalDealRemoving from "@/components/deal/ModalDealRemoving";
import ModalClientCreating from "@/components/client/ModalClientCreating";
import ModalClientUpdating from "@/components/client/ModalClientUpdating";
import ModalClientRemoving from "@/components/client/ModalClientRemoving";
import ModalContractCreating from "@/components/contract/ModalContractCreating";
import ModalContractUpdating from "@/components/contract/ModalContractUpdating";
import ModalContractRemoving from "@/components/contract/ModalContractRemoving";

import ModalSubmanagerCreating from "@/components/submanager/ModalSubmanagerCreating";
import ModalSubmanagerShowing from "@/components/submanager/ModalSubmanagerShowing";
import ModalSubmanagerUpdating from "@/components/submanager/ModalSubmanagerUpdating";
import ModalSubmanagerRemoving from "@/components/submanager/ModalSubmanagerRemoving";

import ModalJuristCreating from "@/components/jurist/ModalJuristCreating.vue";
import ModalJuristShowing from "@/components/jurist/ModalJuristShowing.vue";
import ModalJuristUpdating from "@/components/jurist/ModalJuristUpdating.vue";
import ModalJuristRemoving from "@/components/jurist/ModalJuristRemoving.vue";

import ModalAgentCreating from "@/components/agent/ModalAgentCreating.vue";
import ModalAgentShowing from "@/components/agent/ModalAgentShowing.vue";
import ModalAgentUpdating from "@/components/agent/ModalAgentUpdating.vue";
import ModalAgentRemoving from "@/components/agent/ModalAgentRemoving.vue";

// import ModalPaymentCreating from "@/components/payment/ModalPaymentCreating";
// import ModalPaymentUpdating from "@/components/payment/ModalPaymentUpdating";
// import ModalMeetCreating from "@/components/meet/ModalMeetCreating";
// import ModalMeetUpdating from "@/components/meet/ModalMeetUpdating";

import { Notify } from 'quasar'
import {mapActions, mapState} from "vuex";

export default {
  components: {
    LayoutCabinet,
    ModalDealCreating,
    ModalDealUpdating,
    ModalDealRemoving,
    ModalClientCreating,
    ModalClientUpdating,
    ModalClientRemoving,
    ModalContractCreating,
    ModalContractUpdating,
    ModalContractRemoving,
    ModalSubmanagerCreating,
    ModalSubmanagerShowing,
    ModalSubmanagerUpdating,
    ModalSubmanagerRemoving,
    ModalJuristCreating,
    ModalJuristShowing,
    ModalJuristUpdating,
    ModalJuristRemoving,
    ModalAgentCreating,
    ModalAgentShowing,
    ModalAgentUpdating,
    ModalAgentRemoving,

    // ModalPaymentCreating,
    // ModalPaymentUpdating,
    // ModalMeetCreating,
    // ModalMeetUpdating,
  },
  data() {
    return {
      leftDrawer: {
        isOpen: false,
        activeMenuItem: 'Дела',
        menu: [
          {
            icon: 'gavel',
            label: 'Дела',
            link: '/manager/index',
          },
          {
            icon: 'calendar_month',
            label: 'Календарь',
            link: '/manager/calendar',
          },
          {
            icon: 'description',
            label: 'Договора',
            link: '/manager/contracts',
          },
          {
            icon: 'people_alt',
            label: 'Клиенты',
            link: '/manager/clients',
            separator: true,
          },

          {
            icon: 'adb',
            label: 'Замы',
            link: '/manager/submanagers',
          },
          {
            icon: 'school',
            label: 'Юристы',
            link: '/manager/jurists',
          },
          {
            icon: 'work',
            label: 'Представители',
            link: '/manager/agents',
            separator: true,
          },

          {
            icon: 'payments',
            label: 'Платежи',
            link: '/manager/payments',
          },
          {
            icon: 'handshake',
            label: 'Встречи',
            link: '/manager/meets',
          },


          // {
          //   icon: 'school',
          //   label: 'Тест',
          //   children: [
          //     {
          //       icon: 'people',
          //       label: 'Клиенты',
          //       link: '/manager/clients',
          //     },
          //     {
          //       icon: 'people',
          //       label: 'Клиенты',
          //       link: '/manager/clients',
          //     },
          //   ]
          // },
        ]
      },
    }
  },

  created() {
    this.init()
  },

  computed: {
    ...mapState({
      'createbleClient': state => state.clients.modals.creating.data,
      'updatebleClient': state => state.clients.modals.updating.data,
      // 'removebleClient': state => state.clients.modals.removing.data,
      'createbleContract': state => state.contracts.modals.creating.data,
      'updatebleContract': state => state.contracts.modals.updating.data,
      'createbleDeal': state => state.deals.modals.creating.data,
      'updatebleDeal': state => state.deals.modals.updating.data,
      'createbleJurist': state => state.jurists.modals.creating.data,
      'updatebleJurist': state => state.jurists.modals.updating.data,
      'createbleAgent': state => state.agents.modals.creating.data,
      'updatebleAgent': state => state.agents.modals.updating.data,

      // 'createbleMeet': state => state.modals.creatingMeet.data,
      // 'updatebleMeet': state => state.modals.updatingMeet.data,
      // 'createblePayment': state => state.modals.creatingPayment.data,
      // 'updateblePayment': state => state.modals.updatingPayment.data,

      // 'dataCreateDeal': state => state.modals.creatingDeal.data,
      // 'isOpenCreateDeal': state => state.modals.creatingDeal.isOpen,
    }),
  },

  methods: {
    init() {
      this.createbleAgent.manager_id = 3
    }
  },
}


</script>
