<template>
  <TableClients
    :columns="columns"
  >
  </TableClients>
</template>

<script>

import TableClients from "@/components/client/TableClients.vue";

export default {
  components: {TableClients},
  data() {
    return {
      columns: [
        // {
        //   label: 'ID',
        //   field: row => row.id,
        //   align: 'left',
        // },
        {
          name: 'name',
          label: 'Имя',
          field: row => row.name,
          align: 'left',
        },
        {
          name: 'phone',
          label: 'Телефон',
          field: row => row.phone,
          align: 'left',
        },
        {
          name: 'actions',
          headerStyle: 'width: 60px',
        },
      ],
    }
  },
}
</script>

