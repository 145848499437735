<template>
  <Select
    label="Руководитель"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    :options="this.$store.state.lists.managers"
    optionValue="user_id"

    :rules="rules"
  />
</template>

<script>
import Select from '@/components/ui/Select'

export default {
  name: 'SelectManager',
  components: {
    Select
  },
  props: {
    modelValue: {
      // type: Number,
      // required: true,
    },
    rules: {
      type: Array,
      default: [ val => val || 'Введите руководителя']
    },
  },
}
</script>
