import http from '@/services/http';

export async function getAll({page, filter}){
  let { data } = await http.get('/clients',{params: {page: page, ...filter}});
  return data;
}

export async function getOne(id){
  let { data } = await http.get(`/clients/${id}`);
  return data;
}

export async function create(item){
  let { data } = await http.post('/clients', item);
  return data;
}

export async function update(item){
  let { data } = await http.put(`/clients/${item.id}`, item);
  return data;
}

export async function remove(id){
  let { data } = await http.delete(`/clients/${id}`);
  return data;
}
