<template>
  <q-select
    label="Номер договора:"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"

    emit-value
    map-options

    use-input
    input-debounce="500"

    @filter="search"
    @filter-abort="abortFilterFn"

    :options="clients"
    option-value="id"
    option-label="number"
    filled
    clearable
    class="col-xs-12 col-sm-6 col-md-4"

    :loading="loading"
    :rules="[ val => val || 'Выберите договор']"
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">
          Не найдено
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
export default {
  name: 'SelectContract',
  props: {
    modelValue: {},
  },
  data() {
    return {
      clients: [],
      loading: false,
    }
  },
  methods: {
    search(val, update, abort) {
      // call abort() at any time if you can't retrieve data somehow
      update(() => {
        if (val === '') {
          this.clients = []
        }
        else {
          this.loading = true
          http.get('/contracts/autocomplete?q=' + val)
            .then(response => {
              this.clients = response.data;
            })
            .catch(error => {
              console.log(error)
              this.errored = true;
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },

    abortFilterFn () {
      // console.log('delayed filter aborted')
    }
  },
}
</script>
