import * as authApi from '@/api/auth.js';
import { setTokens, getJWTPayload } from '@/services/tokens';
import {logout} from "@/api/auth.js";
import store from "@/store/index";
import router from "@/router";

let readyRelosver;
let readyPromise = new Promise(function(resolve){
	readyRelosver = resolve;
});

export default {
	namespaced: true,
	state: {
		user: null,
		// user: {
    //   id: '2',
    //   role: 'submanager',
    // },
	},
	getters: {
		ready: state => readyPromise,
		isLogin: state => state.user !== null,
		role: state => state.user ? state.user.role : null,
		//checkRole: state => allowedRoles => state.user !== null && allowedRoles.some(role => state.user.roles.includes(role))
	},
	mutations: {
		setUser(state, user){
			state.user = user
		}
	},
	actions: {
		async autoLogin({ commit }){ // получаем юзера из токена даже после перезагрузки
			let {data} = await authApi.check()
      console.log(data.user)
			if(data.user){
        let { id, role, name } = data.user
				commit('setUser', { id, role, name })
			}

			readyRelosver();
		},

		async login({ commit }, { login, password }){
			let data = await authApi.login(login, password)
			if(!data){
				return { errors: 'Нет связи' }
			}
			else if(data.access_token){
				setTokens(data.access_token)
				let { id, role, name } = getJWTPayload(data.access_token)
				commit('setUser', { id, role, name })
			}

			return data
		},

    async logout({ dispatch }){
      let data = await authApi.logout()
      if(!data){
        return { errors: 'Нет связи' }
      }

      dispatch('clean');
      router.push({name: 'login'}).then(() => {
        // location.reload(); // options vs 33 idea
      });
    },


		clean({ commit }){
			commit('setUser', null)
		}
	}
}
