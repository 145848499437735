<template>
  <q-btn
    flat
    label="Продолжить"
    color="secondary"
  />
</template>

<script>
export default {
  name: 'BtnContinue',
}
</script>
