<template>
  <q-dialog
    :modelValue="modalUpdating.isOpen"
    @update:modelValue="modalUpdating.isOpen = $event"
  >
    <q-card style="width: 900px; max-width: 90vw;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Редактирование встречи</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-form @submit="submit">
        <q-card-section>
          <div class="row q-col-gutter-x-md">
            <select-deal v-model="modalUpdating.data.deal_id" />

            <Select label="Должность"
                    v-model="modalUpdating.data.role_slug"
                    :options="[
                {id: 'jurist', name: 'Юрист'},
                {id: 'agent', name: 'Агент'}
              ]"
              :clearable="false"
            />

            <select-employee v-model="modalUpdating.data.user_id"
              :role="modalUpdating.data.role_slug"
            />

            <date-time-picker label="Время начала"
              v-model="modalUpdating.data.start_at"
            />

            <date-time-picker label="Время окончания"
              v-model="modalUpdating.data.end_at"
            />
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <btn-close />
          <btn-save />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";
import Select from "@/components/ui/Select.vue";

export default {
  components: {Select},
  computed: mapState({
    'modalUpdating': state => state.meets.modals.updating,
  }),

  methods: {
    ...mapActions('meets', [
      'update',
      'eventUpdate'
    ]),
    submit() {
      if (this.$store.state.minicalendar.type === 'meets') {

        this.eventUpdate()
      } else {
        console.log('777777')
        this.update()
      }
    }
  },
}
</script>
