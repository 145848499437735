import * as meetsApi from '@/api/meets'
import date from 'quasar/src/utils/date.js';import Notify from 'quasar/src/plugins/Notify.js';;

export default {
  namespaced: true,
  state: {
    table: {
      data: [],
      filter: {},
      pagination: {
        page: 1,
        rowsPerPage: 15,
        rowsNumber: 0
      },
      loading: false,
    },
    calendar: {
      date: '2024-06-21',
      events: [],
      resources: [],
      getedResources: false,
      getedEvents: false,
    },
    modals: {
      creating: {
        isOpen: false,
        data: {
          role_slug: 'jurist' //TODO при обновлении обнулить user_id
        }
      },
      showing: {
        isOpen: false,
        data: {}
      },
      updating: {
        isOpen: false,
        data: {}
      },
      removing: {
        isOpen: false,
        data: {}
      },
    },
  },
  actions: {
    openModalCreating({state}) {
      state.modals.creating.isOpen = true
    },

    openModalShowing({state}, item) {
      state.modals.showing.isOpen = true
      state.modals.showing.data = item
    },

    openModalUpdating({state}, item) { //TODO значение не должно меняться в таблице
      state.modals.updating.isOpen = true
      state.modals.updating.data = item
    },

    openModalRemoving({state}, item) {
      state.modals.removing.isOpen = true
      state.modals.removing.data = item
    },

    async getAll({state}, { pagination, filter }) {
      state.table.loading = true

      let res = await meetsApi.getAll({
        page: pagination.page,
        filter: filter,
      })

      state.table.data = res.data

      state.table.pagination.page = res.current_page
      state.table.pagination.rowsPerPage = res.per_page
      state.table.pagination.rowsNumber = res.total

      state.table.loading = false

      // console.log(res)
    },

    async create({state}) {
      let createdMeet = await meetsApi.create(state.modals.creating.data)
      console.log(createdMeet)
      state.table.data.unshift(createdMeet)

      state.modals.creating.isOpen = false
      state.modals.creating.data = {
        role_slug: 'jurist'
      }

      Notify.create({
        message: `Встреча создана`,
        color: 'purple'
      })
    },

    async update({state}) {
      let updatedMeet = await meetsApi.update(state.modals.updating.data.id, state.modals.updating.data)

      if (updatedMeet !== '') {
        state.table.data.map(meet => {
          if (meet.id === updatedMeet.id) {
            for (let key in meet) {
              meet[key] = updatedMeet[key];
            }
          }
        })
      }

      state.modals.updating.isOpen = false
      state.modals.updating.data = {}

      Notify.create({
        message: `Встреча изменена`,
        color: 'purple'
      })
    },

    async remove({state}) {
      let removedMeet = await meetsApi.remove(state.modals.removing.data.id)

      state.table.data = state.table.data.filter(meet => meet.id !== removedMeet.id)

      state.modals.removing.isOpen = false
      state.modals.removing.data = {}

      Notify.create({
        message: `Встреча удалена`,
        color: 'purple'
      })
    },
    //--------------- calendar ----------------------------
    getResources({state, rootState}) {
      if (state.calendar.resources.length === 0) {
        rootState.lists.jurists.forEach((jurist) => {
          state.calendar.resources.push({
            id: jurist.user_id,
            submanager: jurist.name,
            title: jurist.surname,
            occupancy: 0
          })
        })
      }

      state.calendar.getedResources = true
    },

    async getEvents({state, rootState}, selectedDate) { //TODO загружать эвенты один раз на месяц
      state.calendar.events = []

      // if (state.calendar.events.length === 0) {
        let meets = await meetsApi.getForCalendar(selectedDate)
      console.log('meets: ' + meets)
        meets.forEach(meet => {
          state.calendar.events.push({
            resourceId: meet.user_id,
            title: meet.deal.client.name,
            start: meet.start_at,
            end: meet.end_at,
            color: 'transparent',
            // display: 'background',        // запрет на сдвиг
            // overlap: false,               // запрет двух событий в одно время

            start_at: date.formatDate(meet.start_at, 'H:m'),
            end_at: date.formatDate(meet.end_at, 'H:m'),
            status: meet.deal.status.name,
            bgColor: meet.deal.status.bg_color,
            meet: meet,
          })

          // state.calendar.resources.forEach((resource) => { //TODO не обновляется количество встреч в календаре
          //   if (resource.id === meet.user_id) {
          //     resource.occupancy += 1
          //   }
          // })

        });
      // }
      state.calendar.getedEvents = true


      // console.log(state.calendar.events)
    },

    eventDrop({state}, info) {
      state.calendar.events.map(async event => {
        if (event.meet.id === info.event.extendedProps.meet.id) {
          let updatedMeet = await meetsApi.update(info.event.extendedProps.meet.id, {
            'start_at': date.formatDate(info.event.start, 'YYYY-MM-DD HH:mm:ss'),
            'end_at': date.formatDate(info.event.end, 'YYYY-MM-DD HH:mm:ss')
          })

          event.start = updatedMeet.start_at
          event.end = updatedMeet.end_at
          event.start_at = date.formatDate(updatedMeet.start_at, 'H:m')
          event.end_at = date.formatDate(updatedMeet.end_at, 'H:m')
          event.meet.start_at = date.formatDate(updatedMeet.start_at, 'YYYY-MM-DD HH:mm:ss')
          event.meet.end_at = date.formatDate(updatedMeet.end_at, 'YYYY-MM-DD HH:mm:ss')

          Notify.create({
            message: `Время встречи с ${info.event.extendedProps.meet.deal.client.name} изменено`,
            color: 'purple'
          })
        }
      })

      // console.log(start_date)
    },

    eventClick({state}, info) {
      state.modals.updating.isOpen = true
      state.modals.updating.data = info.event.extendedProps.meet
    },

    async eventUpdate({state}) {
      console.log('11111')

      let updatedMeet = await meetsApi.update(state.modals.updating.data.id, state.modals.updating.data)

      if (updatedMeet !== '') {
        state.calendar.events.map(event => {
          if (event.meet.id === updatedMeet.id) {
            event.resourceId = updatedMeet.user_id
            event.start = updatedMeet.start_at
            event.end = updatedMeet.end_at
            event.start_at = date.formatDate(updatedMeet.start_at, 'H:m')
            event.end_at = date.formatDate(updatedMeet.end_at, 'H:m')
            event.meet.start_at = date.formatDate(updatedMeet.start_at, 'YYYY-MM-DD HH:mm:ss')
            event.meet.end_at = date.formatDate(updatedMeet.end_at, 'YYYY-MM-DD HH:mm:ss')
          }
        })
      }

      state.modals.updating.isOpen = false
      state.modals.updating.data = {}

      Notify.create({
        message: `Встреча изменена`,
        color: 'purple'
      })
    },

    async eventResize({state}, info) {
      state.calendar.events.map(async event => {
        if (event.meet.id === info.event.extendedProps.meet.id) {
          let updatedMeet = await meetsApi.update(info.event.extendedProps.meet.id, {
            'start_at': date.formatDate(info.event.start, 'YYYY-MM-DD HH:mm:ss'),
            'end_at': date.formatDate(info.event.end, 'YYYY-MM-DD HH:mm:ss')
          })

          event.start = updatedMeet.start_at
          event.end = updatedMeet.end_at
          event.start_at = date.formatDate(updatedMeet.start_at, 'H:m')
          event.end_at = date.formatDate(updatedMeet.end_at, 'H:m')
          event.meet.start_at = date.formatDate(updatedMeet.start_at, 'YYYY-MM-DD HH:mm:ss')
          event.meet.end_at = date.formatDate(updatedMeet.end_at, 'YYYY-MM-DD HH:mm:ss')

          Notify.create({
            message: `Время встречи с ${info.event.extendedProps.meet.deal.client.name} изменено`,
            color: 'purple'
          })
        }
      })
    },
  },

}
