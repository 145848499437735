<template>
  <q-input
    filled
    :label="label + ':'"
    :modelValue="modelValue"
    @update:modelValue="modelValue = $event"
    class="col-xs-12 col-sm-6 col-md-4"

    :rules="rules"
    :error="error"
    :error-message="errorMessage"
  >
    <template v-slot:prepend>
      <q-icon name="event" class="cursor-pointer">
        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
          <q-date
            :modelValue="modelValue"
            @update:modelValue="$emit('update:modelValue', $event)"
            mask="YYYY-MM-DD"
          >
            <div class="row items-center justify-end">
              <q-btn v-close-popup label="Закрыть" color="primary" flat />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    modelValue: {},
    label: {
      type: String,
    },
    rules: {
      type: Array,
      default: []
    },
    error: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
    },
  }
}
</script>
