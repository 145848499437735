<template>
  <q-dialog
    :modelValue="modalCreating.isOpen"
    @update:modelValue="modalCreating.isOpen = $event"
  >
    <q-card style="width: 900px; max-width: 90vw;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Создание дела</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>
      <q-form @submit="create">
        <q-card-section>
          <div class="row q-col-gutter-x-md">
            <select-client v-model="modalCreating.data.client_id" />
            <select-contract v-model="modalCreating.data.contract_id" />
            <select-stage v-model="modalCreating.data.stage_id" />
            <select-subject v-model="modalCreating.data.subject_id" />
            <select-theme v-model="modalCreating.data.theme_id" />
            <select-completed v-model="modalCreating.data.completed_id" />
            <select-actresult v-model="modalCreating.data.act_result_id" />
            <select-appeal v-model="modalCreating.data.appeal_id" />
            <select-status v-model="modalCreating.data.status_id" />
            <select-result v-model="modalCreating.data.result_id" />
            <!--    <text-comment v-model="modalCreating.data.comment" />-->
            <slot />
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <btn-save />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  computed: mapState({
    'modalCreating': state => state.deals.modals.creating,
  }),

  methods: {
    ...mapActions('deals', [
      'create'
    ]),
  },
}
</script>
