<template>
  <LayoutCabinet
    :leftDrawer="leftDrawer"
  />

  <ModalDealCreating>
    <select-submanager v-model="createbleDeal.submanager2_id" isSecond />
    <select-jurist v-model="createbleDeal.jurist_id" />
    <select-jurist v-model="createbleDeal.jurist2_id" isSecond />
    <select-agent v-model="createbleDeal.agent_id" />
  </ModalDealCreating>
  <ModalDealUpdating>
    <select-submanager v-model="updatebleDeal.submanager2_id" isSecond />
    <select-jurist v-model="updatebleDeal.jurist_id" />
    <select-jurist v-model="updatebleDeal.jurist2_id" isSecond />
    <select-agent v-model="updatebleDeal.agent_id" />
  </ModalDealUpdating>
  <ModalDealRemoving />

  <ModalContractCreating />
  <ModalContractUpdating />
  <ModalContractRemoving />

  <ModalClientCreating />
  <ModalClientUpdating />
  <ModalClientRemoving />

  <ModalAgentCreating />
  <ModalAgentShowing />
  <ModalAgentUpdating />
  <ModalAgentRemoving />

  <ModalPaymentCreating />
  <ModalPaymentShowing />
  <ModalPaymentUpdating />
  <ModalPaymentRemoving />

  <ModalMeetCreating />
  <ModalMeetShowing />
  <ModalMeetUpdating />
  <ModalMeetRemoving />

  <ModalJuristCreating />
  <ModalJuristShowing />
  <ModalJuristUpdating />
  <ModalJuristRemoving />


</template>

<script>
import LayoutCabinet from "@/components/LayoutCabinet";
import ModalClientCreating from "@/components/client/ModalClientCreating";
import ModalClientUpdating from "@/components/client/ModalClientUpdating";
import ModalClientRemoving from "@/components/client/ModalClientRemoving";
import ModalContractCreating from "@/components/contract/ModalContractCreating";
import ModalContractUpdating from "@/components/contract/ModalContractUpdating";
import ModalContractRemoving from "@/components/contract/ModalContractRemoving";
import ModalDealCreating from "@/components/deal/ModalDealCreating";
import ModalDealUpdating from "@/components/deal/ModalDealUpdating";
import ModalDealRemoving from "@/components/deal/ModalDealRemoving";
import ModalPaymentCreating from "@/components/payment/ModalPaymentCreating";
import ModalPaymentShowing from "@/components/payment/ModalPaymentShowing";
import ModalPaymentUpdating from "@/components/payment/ModalPaymentUpdating";
import ModalPaymentRemoving from "@/components/payment/ModalPaymentRemoving";
import ModalMeetCreating from "@/components/meet/ModalMeetCreating";
import ModalMeetShowing from "@/components/meet/ModalMeetShowing";
import ModalMeetUpdating from "@/components/meet/ModalMeetUpdating";
import ModalMeetRemoving from "@/components/meet/ModalMeetRemoving";
import ModalJuristCreating from "@/components/jurist/ModalJuristCreating";
import ModalJuristShowing from "@/components/jurist/ModalJuristShowing";
import ModalJuristUpdating from "@/components/jurist/ModalJuristUpdating";
import ModalJuristRemoving from "@/components/jurist/ModalJuristRemoving";
import ModalAgentCreating from "@/components/agent/ModalAgentCreating";
import ModalAgentShowing from "@/components/agent/ModalAgentShowing";
import ModalAgentUpdating from "@/components/agent/ModalAgentUpdating";
import ModalAgentRemoving from "@/components/agent/ModalAgentRemoving";

import { Notify } from 'quasar'
import {mapState} from "vuex";

export default {
  components: {
    LayoutCabinet,
    ModalClientCreating,
    ModalClientUpdating,
    ModalClientRemoving,
    ModalContractCreating,
    ModalContractUpdating,
    ModalContractRemoving,
    ModalDealCreating,
    ModalDealUpdating,
    ModalDealRemoving,
    ModalPaymentCreating,
    ModalPaymentShowing,
    ModalPaymentUpdating,
    ModalPaymentRemoving,
    ModalMeetCreating,
    ModalMeetShowing,
    ModalMeetUpdating,
    ModalMeetRemoving,
    ModalJuristCreating,
    ModalJuristShowing,
    ModalJuristUpdating,
    ModalJuristRemoving,
    ModalAgentCreating,
    ModalAgentShowing,
    ModalAgentUpdating,
    ModalAgentRemoving,
  },
  data() {
    return {
      leftDrawer: {
        isOpen: false,
        activeMenuItem: 'Дела',
        menu: [
          {
            icon: 'gavel',
            label: 'Дела',
            link: '/submanager/index',
          },
          {
            icon: 'calendar_month',
            label: 'Календарь',
            link: '/submanager/calendar',
          },
          {
            icon: 'description',
            label: 'Договора',
            link: '/submanager/contracts',
          },
          {
            icon: 'people_alt',
            label: 'Клиенты',
            link: '/submanager/clients',
            separator: true,
          },

          {
            icon: 'school',
            label: 'Юристы',
            link: '/submanager/jurists',
          },
          {
            icon: 'work',
            label: 'Представители',
            link: '/submanager/agents',
            separator: true,
          },

          {
            icon: 'payments',
            label: 'Платежи',
            link: '/submanager/payments',
          },
          {
            icon: 'handshake',
            label: 'Встречи',
            link: '/submanager/meets',
          },


          // {
          //   icon: 'school',
          //   label: 'Тест',
          //   children: [
          //     {
          //       icon: 'people',
          //       label: 'Клиенты',
          //       link: '/manager/clients',
          //     },
          //     {
          //       icon: 'people',
          //       label: 'Клиенты',
          //       link: '/manager/clients',
          //     },
          //   ]
          // },
        ]
      },
    }
  },
  computed: {
    ...mapState({
      'createbleClient': state => state.clients.modals.creating.data,
      'updatebleClient': state => state.clients.modals.updating.data,
      // 'removebleClient': state => state.clients.modals.removing.data,
      'createbleContract': state => state.contracts.modals.creating.data,
      'updatebleContract': state => state.contracts.modals.updating.data,
      'createbleDeal': state => state.deals.modals.creating.data,
      'updatebleDeal': state => state.deals.modals.updating.data,
      // 'createbleMeet': state => state.modals.creatingMeet.data,
      // 'updatebleMeet': state => state.modals.updatingMeet.data,
      // 'createblePayment': state => state.modals.creatingPayment.data,
      // 'updateblePayment': state => state.modals.updatingPayment.data,

      // 'dataCreateDeal': state => state.modals.creatingDeal.data,
      // 'isOpenCreateDeal': state => state.modals.creatingDeal.isOpen,
    }),
  },
}


</script>
