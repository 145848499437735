import * as dealsApi from '@/api/deals'
import Notify from 'quasar/src/plugins/Notify.js';;

export default {
  namespaced: true,
  state: {
    table: {
      data: [],
      filter: {
        // date: ''
      },
      pagination: {
        page: 1,
        rowsPerPage: 15,
        rowsNumber: 0
      },
      loading: false,
    },
    modals: {
      creating: {
        isOpen: false,
        data: {
          stage_id: 1
        }
      },
      updating: {
        isOpen: false,
        data: {}
      },
      removing: {
        isOpen: false,
        data: {}
      },
    },
  },
  actions: {
    openModalCreating({state}) {
      state.modals.creating.isOpen = true
    },

    openModalUpdating({state}, item) { //TODO значение не должно меняться в таблице
      state.modals.updating.isOpen = true
      state.modals.updating.data = item
    },

    openModalRemoving({state}, item) {
      state.modals.removing.isOpen = true
      state.modals.removing.data = item
    },

    async getAll({state, rootState}, { pagination, filter }) {
      state.table.loading = true

      let res = await dealsApi.getAll({
        page: pagination.page,
        filter: filter,
        date: rootState.minicalendar.selectedDate,
        // filter: {
        //   ...filter,
        //   date: rootState.minicalendar.selectedDate,
        // }
      })

      state.table.data = res.data

      state.table.pagination.page = res.current_page
      state.table.pagination.rowsPerPage = res.per_page
      state.table.pagination.rowsNumber = res.total

      state.table.loading = false

      // console.log(res)
    },

    async create({state}) {
      let createdDeal = await dealsApi.create(state.modals.creating.data)

      state.table.data.unshift(createdDeal)

      state.modals.creating.isOpen = false
      state.modals.creating.data = {
        stage_id: 1
      }

      Notify.create({
        message: `Дело ${ createdDeal.id } создано`,
        color: 'purple'
      })
    },

    async update({state}) {
      let updatedDeal = await dealsApi.update(state.modals.updating.data)

      if (updatedDeal !== '') {
        state.table.data.map(deal => {
          if (deal.id === updatedDeal.id) {
            for (let key in deal) {
              deal[key] = updatedDeal[key];
            }
          }
        })
      }

      state.modals.updating.isOpen = false
      state.modals.updating.data = {}

      Notify.create({
        message: `Дело ${ updatedDeal.id } изменено`,
        color: 'purple'
      })
    },

    async remove({state}) {
      let removedDeal = await dealsApi.remove(state.modals.removing.data.id)

      // state.table.data.splice(state.modals.removing.data.index, 1)
      state.table.data = state.table.data.filter(deal => deal.id !== removedDeal.id)

      state.modals.removing.isOpen = false
      state.modals.removing.data = {}

      Notify.create({
        message: `Договор №${ removedDeal.number } удален`,
        color: 'purple'
      })
    },


  },



  // // getters: {
  // //   showModalCreateDeal: state => state.modals.createDeal.isOpen,
  // //   createbleDeal: state => state.modals.createDeal.data,
  // // },
  // mutations: {
  //   setItems(state, items){
  //     state.items = items;
  //   }
  // },
  // actions: {
  //   openModalDealCreating({state}) {
  //     state.modals.creating.isOpen = true
  //   },
  //
  //   openModalDealUpdating({state}, deal) {
  //     state.modals.updating.isOpen = true
  //     state.modals.updating.data = deal
  //   },
  //
  //   // closeModalDealCreating({state}){
  //   //   state.modals.creatingDeal.isOpen = false
  //   //   state.modals.creatingDeal.data = {}
  //   // },
  // }
}
