<template>
  <q-dialog
    :modelValue="modalCreating.isOpen"
    @update:modelValue="modalCreating.isOpen = $event"
  >
    <q-card style="width: 900px; max-width: 90vw;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Создание юриста</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-form @submit="create">
        <q-card-section>
          <div class="row q-col-gutter-x-md">
            <slot />
<!--            <select-submanager v-model="modalCreating.data.submanager_id"/>-->
            <input-name v-model="modalCreating.data.name"/>
            <input-surname v-model="modalCreating.data.surname"/>
            <input-patronymic v-model="modalCreating.data.patronymic" :rules="[]"/>
            <input-phone v-model="modalCreating.data.phone" :rules="[]"/>

            <input-login v-model="modalCreating.data.login"/>
            <input-password v-model="modalCreating.data.password"/>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <btn-close />
          <btn-save />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  computed: mapState({
    'modalCreating': state => state.jurists.modals.creating,
  }),

  // created() {
  //   this.init()
  // },

  methods: {
    ...mapActions('jurists', [
      'create'
    ]),

    // init() {
    //   this.modalCreating.data.submanager_id = 6
    // }
  },
}
</script>
