import * as juristsApi from '@/api/jurists'
import Notify from 'quasar/src/plugins/Notify.js';
export default {
  namespaced: true,
  state: {
    table: {
      data: [],
      filter: {},
      pagination: {
        page: 1,
        rowsPerPage: 15,
        rowsNumber: 0
      },
      loading: false,
    },
    modals: {
      creating: {
        isOpen: false,
        data: {}
      },
      showing: {
        isOpen: false,
        data: {}
      },
      updating: {
        isOpen: false,
        data: {}
      },
      removing: {
        isOpen: false,
        data: {}
      },
    },
  },
  getters: {
    // all: state => state.items,
    // one: state => id => state.items.find(item => item.id == id),
    // в компоненте {{ $store.getters['jurists/one']($route.params.id) }}
  },
  mutations: {

  },
  actions: {
    openModalCreating({state}) {
      state.modals.creating.isOpen = true
    },

    openModalShowing({state}, item) {
      state.modals.showing.isOpen = true
      state.modals.showing.data = item
    },

    openModalUpdating({state}, jurist) {
      state.modals.updating.isOpen = true
      state.modals.updating.data = jurist
    },

    openModalRemoving({state}, jurist) {
      state.modals.removing.isOpen = true
      state.modals.removing.data = jurist
    },
    // changePagination({state}, pagination) {
    //   state.table.pagination = pagination
    // },
    async getAll({state}, { pagination, filter }) {
      state.table.loading = true

      let res = await juristsApi.getAll({
        page: pagination.page,
        filter: filter,
      })

      state.table.data = res.data

      state.table.pagination.page = res.current_page
      state.table.pagination.rowsPerPage = res.per_page
      state.table.pagination.rowsNumber = res.total

      state.table.loading = false

      // commit('set', { cart, token });
      console.log(res)
    },

    async create({state}) {
      let createdJurist = await juristsApi.create(state.modals.creating.data)

      state.table.data.unshift(createdJurist)

      state.modals.creating.isOpen = false
      state.modals.creating.data = {}

      Notify.create({
        message: `Юрист ${createdJurist.name} создан`,
        color: 'purple'
      })
    },

    async update({state}) {
      let updatedJurist = await juristsApi.update(state.modals.updating.data)

      if (updatedJurist !== '') {
        state.table.data.map(c => {
          if (c.user_id === updatedJurist.user_id) {
            for (let key in c) {
              c[key] = updatedJurist[key];
            }
          }
        })
      }

      state.modals.updating.isOpen = false
      state.modals.updating.data = {}

      Notify.create({
        message: `Юрист ${updatedJurist.name} изменен`,
        color: 'purple'
      })
    },

    async remove({state}) {
      let removedJurist = await juristsApi.remove(state.modals.removing.data.user_id)

      state.table.data = state.table.data.filter(jurist => jurist.user_id !== removedJurist.user_id)

      state.modals.removing.isOpen = false
      state.modals.removing.data = {}

      Notify.create({
        message: `Юрист ${removedJurist.name} удален`,
        color: 'purple'
      })
    },
  },
}
