<template>
  <q-btn
    dense
    flat
    color="red"
    icon="delete_forever"
  >
    <q-tooltip class="bg-accent">
      <slot />
    </q-tooltip>
  </q-btn>
</template>

<script>
export default {
  name: 'ActRemove',
}
</script>
