<template>
  <LayoutCabinet
    :leftDrawer="leftDrawer"
  />

  <ModalDealCreating>
    <select-submanager v-model="createbleDeal.submanager2_id" isSecond />
    <select-jurist v-model="createbleDeal.jurist_id" />
    <select-jurist v-model="createbleDeal.jurist2_id" isSecond />
    <select-agent v-model="createbleDeal.agent_id" />
  </ModalDealCreating>
  <ModalDealUpdating>
    <select-submanager v-model="updatebleDeal.submanager2_id" isSecond />
    <select-jurist v-model="updatebleDeal.jurist_id" />
    <select-jurist v-model="updatebleDeal.jurist2_id" isSecond />
    <select-agent v-model="updatebleDeal.agent_id" />
  </ModalDealUpdating>
  <ModalDealRemoving />

  <ModalMeetCreating />
  <ModalMeetShowing />
  <ModalMeetUpdating />
  <ModalMeetRemoving />

  <ModalContractCreating />
  <ModalContractUpdating />
  <ModalContractRemoving />

  <ModalClientCreating />
  <ModalClientUpdating />
  <ModalClientRemoving />

  <ModalAgentCreating />
  <ModalAgentShowing />
  <ModalAgentUpdating />
  <ModalAgentRemoving />

</template>

<script>
import LayoutCabinet from "@/components/LayoutCabinet";
import ModalClientCreating from "@/components/client/ModalClientCreating";
import ModalClientUpdating from "@/components/client/ModalClientUpdating";
import ModalClientRemoving from "@/components/client/ModalClientRemoving";
import ModalContractCreating from "@/components/contract/ModalContractCreating";
import ModalContractUpdating from "@/components/contract/ModalContractUpdating";
import ModalContractRemoving from "@/components/contract/ModalContractRemoving";
import ModalDealCreating from "@/components/deal/ModalDealCreating";
import ModalDealUpdating from "@/components/deal/ModalDealUpdating";
import ModalDealRemoving from "@/components/deal/ModalDealRemoving";
// import ModalPaymentCreating from "@/components/payment/ModalPaymentCreating";
// import ModalPaymentUpdating from "@/components/payment/ModalPaymentUpdating";
// import ModalMeetCreating from "@/components/meet/ModalMeetCreating";
// import ModalMeetUpdating from "@/components/meet/ModalMeetUpdating";

import { Notify } from 'quasar'
import {mapState} from "vuex";
import SelectSubmanager from "@/components/ui/SelectSubmanager.vue";
import ModalAgentRemoving from "@/components/agent/ModalAgentRemoving.vue";
import ModalAgentShowing from "@/components/agent/ModalAgentShowing.vue";
import ModalAgentUpdating from "@/components/agent/ModalAgentUpdating.vue";
import ModalAgentCreating from "@/components/agent/ModalAgentCreating.vue";
import ModalMeetCreating from "@/components/meet/ModalMeetCreating.vue";
import ModalMeetShowing from "@/components/meet/ModalMeetShowing.vue";
import ModalMeetRemoving from "@/components/meet/ModalMeetRemoving.vue";
import ModalMeetUpdating from "@/components/meet/ModalMeetUpdating.vue";

export default {
  components: {
    ModalMeetUpdating, ModalMeetRemoving, ModalMeetShowing, ModalMeetCreating,
    ModalAgentCreating, ModalAgentUpdating, ModalAgentShowing, ModalAgentRemoving,
    SelectSubmanager,
    LayoutCabinet,
    ModalClientCreating,
    ModalClientUpdating,
    ModalClientRemoving,
    ModalContractCreating,
    ModalContractUpdating,
    ModalContractRemoving,
    ModalDealCreating,
    ModalDealUpdating,
    ModalDealRemoving,
    // ModalPaymentCreating,
    // ModalPaymentUpdating,
    // ModalMeetCreating,
    // ModalMeetUpdating,
  },
  data() {
    return {
      leftDrawer: {
        isOpen: false,
        activeMenuItem: 'Дела',
        menu: [
          {
            icon: 'gavel',
            label: 'Дела',
            link: '/jurist/index',
          },
          {
            icon: 'calendar_month',
            label: 'Календарь',
            link: '/jurist/calendar',
          },
          {
            icon: 'description',
            label: 'Договора',
            link: '/jurist/contracts',
          },
          {
            icon: 'people_alt',
            label: 'Клиенты',
            link: '/jurist/clients',
            separator: true,
          },

          {
            icon: 'work',
            label: 'Представители',
            link: '/jurist/agents',
          },
        ]
      },
    }
  },
  computed: {
    ...mapState({
      'createbleClient': state => state.clients.modals.creating.data,
      'updatebleClient': state => state.clients.modals.updating.data,
      // 'removebleClient': state => state.clients.modals.removing.data,
      'createbleContract': state => state.contracts.modals.creating.data,
      'updatebleContract': state => state.contracts.modals.updating.data,
      'createbleDeal': state => state.deals.modals.creating.data,
      'updatebleDeal': state => state.deals.modals.updating.data,
      // 'createbleMeet': state => state.modals.creatingMeet.data,
      // 'updatebleMeet': state => state.modals.updatingMeet.data,
      // 'createblePayment': state => state.modals.creatingPayment.data,
      // 'updateblePayment': state => state.modals.updatingPayment.data,

      // 'dataCreateDeal': state => state.modals.creatingDeal.data,
      // 'isOpenCreateDeal': state => state.modals.creatingDeal.isOpen,
    }),
  },
}


</script>
