<template>
  <Select
    label="Сотрудник"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    :options="options"
    optionValue="user_id"

    :rules="rules"
  />
</template>

<script>
import Select from '@/components/ui/Select'

export default {
  name: 'SelectEmployee',
  components: {
    Select
  },
  props: {
    modelValue: {
      // type: Number,
      // required: true,
    },
    role: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      default: [ val => val || 'Выберите сотрудника']
    },
  },
  computed: {
    options() {
      if(this.role === 'jurist') {
        return this.$store.state.lists.jurists
      }
      if(this.role === 'agent') {
        return this.$store.state.lists.agents
      }
      return []
    }
  }
}
</script>
