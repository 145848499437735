import { createRouter, createWebHistory } from 'vue-router'
import LoginLayout from '@/layouts/LayoutLogin'
import LayoutManager from '@/layouts/LayoutManager'
import LayoutSubmanager from '@/layouts/LayoutSubmanager'
import LayoutJurist from '@/layouts/LayoutJurist'
import LayoutAgent from '@/layouts/LayoutAgent'
import Clients  from '@/views/submanager/Clients'
import store from '@/store'

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: LoginLayout,
    async beforeEnter(from, to, next){
      // если юзер залогинен, выкидываем его из логина в кабинет
      await store.getters['user/ready'];
      store.getters['user/isLogin'] ? next({ name: store.getters['user/role'] + '.index' }) : next();
    }
  },
  {
    path: '/manager',
    component: LayoutManager,
    meta: { role: 'manager', auth: true },
    children: [
      {path: '', redirect: '/manager/index'},
      {path: 'index', name: 'manager.index', component: () => import("@/views/manager/Index")},
      {path: 'calendar',   name: 'manager.calendar', component: () => import("@/views/manager/Calendar")},
      {path: 'contracts',   name: 'manager.contracts', component: () => import("@/views/manager/Contracts")},
      {path: 'submanagers', name: 'manager.submanagers', component: () => import("@/views/manager/Submanagers")},
      {path: 'jurists', name: 'manager.jurists', component: () => import("@/views/manager/Jurists")},
      {path: 'agents', name: 'manager.agents', component: () => import("@/views/manager/Agents")},
      {path: 'clients', name: 'manager.clients', component: Clients},
    ]
  },
  {
    path: '/submanager',
    component: LayoutSubmanager,
    meta: { role: 'submanager', auth: true },
    children: [
      {path: '', redirect: '/submanager/index'},
      {path: 'index', name: 'submanager.index', component: () => import("@/views/submanager/Index")},
      {path: 'calendar', name: 'submanager.calendar', component: () => import("@/views/submanager/Calendar")},
      {path: 'contracts', name: 'submanager.contracts', component: () => import("@/views/submanager/Contracts")},
      {path: 'jurists', name: 'submanager.jurists', component: () => import("@/views/submanager/Jurists")},
      {path: 'agents', name: 'submanager.agents', component: () => import("@/views/submanager/Agents")},
      {path: 'clients', name: 'submanager.clients', component: Clients},
      {path: 'payments', name: 'submanager.payments', component: () => import("@/views/submanager/Payments")},
      {path: 'meets', name: 'submanager.meets', component: () => import("@/views/submanager/Meets")},
    ]
  },
  {
    path: '/jurist',
    component: LayoutJurist,
    meta: { role: 'jurist', auth: true },
    children: [
      {path: '', redirect: '/jurist/index'},
      {path: 'index', name: 'jurist.index', component: () => import("@/views/jurist/Index")},
      {path: 'calendar', name: 'jurist.calendar', component: () => import("@/views/jurist/Calendar")},
      {path: 'contracts', name: 'jurist.contracts', component: () => import("@/views/jurist/Contracts")},
      {path: 'agents', name: 'jurist.agents', component: () => import("@/views/jurist/Agents")},
      {path: 'clients', name: 'jurist.clients', component: () => import("@/views/jurist/Clients")},
    ]
  },
  {
    path: '/agent',
    component: LayoutAgent,
    meta: { role: 'agent', auth: true },
    children: [
      {path: '', redirect: '/agent/index'},
      {path: 'index', name: 'agent.index', component: () => import("@/views/agent/Index")},
      {path: 'calendar', name: 'agent.calendar', component: () => import("@/views/agent/Calendar")},
      {path: 'contracts', name: 'agent.contracts', component: () => import("@/views/agent/Contracts")},
      {path: 'clients', name: 'agent.clients', component: () => import("@/views/agent/Clients")},
    ]
  },
  {
    path: '/:any(.*)',
    component: () => import('@/layouts/Layout404'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if(to.meta.auth) { //если страница закрыта
    await store.getters['user/ready'];
    if (store.getters['user/isLogin']) { //проверяем, что юзер залогинен
      //проверяем находится ли юзер в своем кабинете
      if (to.meta.role === store.getters['user/role']) {
        // await store.getters['user/ready'];
        store.dispatch("minicalendar/changeCalendar", {routeName: to.name}) // меняем мини-календарь
        next()
      } else {
        console.log('dfdfd')
        //если юзер не в своем кабинете, то закидываем его в свой кабинет
        next({ name: store.getters['user/role'] + '.index' })
      }
    } else {
      next({ name: 'login' })
    }

    // //проверяем находится ли юзер в своем кабинете
    // if (to.meta.role === store.getters['user/role']) {
    //   // await store.getters['user/ready'];
    //   store.dispatch("minicalendar/changeCalendar", {routeName: to.name}) // меняем мини-календарь
    //
    //   store.getters['user/isLogin'] ? next() : next({ name: 'login' });
    // } else {
    //   console.log('dfdfd')
    //   //если юзер не в своем кабинете, то закидываем его в свой кабинет
    //   next({ name: store.getters['user/role'] + '.index' })
    // }
  } else {
    next();
  }
});

export default router
