import router from '@/router'

import date from 'quasar/src/utils/date.js';import Notify from 'quasar/src/plugins/Notify.js';
export default {
  namespaced: true,
  state: {
    selectedDate: '',
    currentRoute: '',
    type: '',
    routes: [
      {
        name: 'submanager.index',
        date: '',
        type: 'table',
      },
      {
        name: 'submanager.calendar',
        date: '',
        type: 'meets',
      },
    ]
  },

  // getters: {
    // routes(state, getters, rootState, rootGetters) {

    // }
  // },

  actions: {
    load({state}) {
      state.currentRoute = router.currentRoute.value.name
      state.selectedDate = date.formatDate(new Date(), 'YYYY-MM-DD')
    },

    changeCalendar({state}, {routeName}) {
      state.routes.forEach(function(route) {
        if (route.name === routeName) {
          state.currentRoute = route.name
          state.type = route.type

          if (route.date && route.date.length > 0) {
            state.selectedDate = route.date
          } else {
            state.selectedDate = date.formatDate(new Date(), 'YYYY-MM-DD')
          }
        }
      })
    },

    changeDate({state}, value, reason, details) {
      state.routes.forEach(function(route) {
        if (route.name === state.currentRoute) {
          state.selectedDate = value
          route.date = value
        }
      })
    }
  },
}
