import ActShow from "@/components/ui/ActShow";
import ActUpdate from "@/components/ui/ActUpdate";
import ActRemove from "@/components/ui/ActRemove";
import BtnSquare from "@/components/ui/BtnSquare";
import BtnSave from "@/components/ui/BtnSave";
import BtnContinue from "@/components/ui/BtnContinue";
import BtnClose from "@/components/ui/BtnClose";
import BtnRound from '@/components/ui/BtnRound';
import Input from '@/components/ui/Input';
import InputName from '@/components/ui/InputName';
import InputSurname from '@/components/ui/InputSurname';
import InputPatronymic from '@/components/ui/InputPatronymic';
import InputLogin from '@/components/ui/InputLogin';
import InputPassword from '@/components/ui/InputPassword';
import InputPhone from '@/components/ui/InputPhone';
import InputNumber from '@/components/ui/InputNumber';
import InputPin from '@/components/ui/InputPin';
import InputSum from '@/components/ui/InputSum';
import InputTransport from '@/components/ui/InputTransport';
import InputPrepay from '@/components/ui/InputPrepay';
import InputBalance from '@/components/ui/InputBalance';
import DatePicker from '@/components/ui/DatePicker';
import DateTimePicker from '@/components/ui/DateTimePicker';
import Textarea from '@/components/ui/Textarea';
import TextComment from '@/components/ui/TextComment';
import SelectStage from '@/components/ui/SelectStage';
import SelectSubject from '@/components/ui/SelectSubject';
import SelectTheme from '@/components/ui/SelectTheme';
import SelectCompleted from '@/components/ui/SelectCompleted';
import SelectActresult from '@/components/ui/SelectActresult';
import SelectAppeal from '@/components/ui/SelectAppeal';
import SelectStatus from '@/components/ui/SelectStatus';
import SelectResult from '@/components/ui/SelectResult';
import SelectManager from '@/components/ui/SelectManager';
import SelectSubmanager from '@/components/ui/SelectSubmanager';
import SelectJurist from '@/components/ui/SelectJurist';
import SelectAgent from '@/components/ui/SelectAgent';
import SelectClient from '@/components/ui/SelectClient';
import SelectContract from '@/components/ui/SelectContract';
import SelectDeal from '@/components/ui/SelectDeal';
import SelectPaymentType from '@/components/ui/SelectPaymentType';
import SelectEmployee from '@/components/ui/SelectEmployee';

export default [
  ActShow,
  ActUpdate,
  ActRemove,
  BtnSquare,
  BtnSave,
  BtnContinue,
  BtnClose,
  BtnRound,
  Input,
  InputName,
  InputSurname,
  InputPatronymic,
  InputLogin,
  InputPassword,
  InputPhone,
  InputNumber,
  InputPin,
  InputSum,
  InputTransport,
  InputPrepay,
  InputBalance,
  DatePicker,
  DateTimePicker,
  Textarea,
  TextComment,
  SelectStage,
  SelectSubject,
  SelectTheme,
  SelectCompleted,
  SelectActresult,
  SelectAppeal,
  SelectStatus,
  SelectResult,
  SelectManager,
  SelectSubmanager,
  SelectJurist,
  SelectAgent,
  SelectClient,
  SelectContract,
  SelectDeal,
  SelectPaymentType,
  SelectEmployee,
]

