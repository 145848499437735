<template>
  <Input
    label="Имя"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"

    :rules="rules"
  />
</template>

<script>
import Input from '@/components/ui/Input'

export default {
  name: 'InputName',
  components: {
    Input
  },
  props: {
    modelValue: {
      // type: Number,
      // required: true,
    },
    rules: {
      type: Array,
      default: [ val => val && val.length > 0 || 'Введите имя']
    },
  }
}
</script>
