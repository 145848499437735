import http from '@/services/http';

export async function login(login, password) {
  let { data } = await http.post('auth/login', {login, password})
  console.log(data)
  return data
}

export async function logout() {
  let data= await http.get('auth/logout')
  return data
}

export async function check() {
  let data = await http.get('auth/check')
  return data
}
