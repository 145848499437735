<template>
  <div class="q-pa-md bg-light-green window-height window-width row justify-center items-center">
    <div class="column">
      <!-- <div class="row">
        <h5 class="text-h5 text-white q-my-md">Company & Co</h5>
      </div> -->
      <div class="row">
        <q-card bordered class="q-pa-lg shadow-1">
          <q-card-section>
            <q-form
              @submit="tryLogin"
              class="q-gutter-sm"
            >
              <q-input
                filled
                v-model="authData.login"
                label="Логин"
                :rules="[ val => val && val.length > 0 || 'Введите свой логин']"
              >
                <template v-slot:prepend>
                  <q-icon name="person" />
                </template>
              </q-input>

              <q-input
                filled
                :type="passwordFieldType"
                v-model="authData.password"
                label="Пароль"
                :rules="[ val => val && val.length > 0 || 'Введите свой пароль']"
              >
                <template v-slot:prepend>
                  <q-icon name="lock" />
                </template>
                <template v-slot:append>
                  <q-icon
                    :name="visibilityIcon"
                     @click="switchVisibility"
                     class="cursor-pointer"
                  />
                </template>
              </q-input>

              <div>
                <q-btn
                  unelevated
                  color="light-green-7"
                  size="lg"
                  type="submit"
                  class="full-width"
                  label="Войти"
                />
              </div>
            </q-form>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data () {
    return {
      authData: {
        login: 'submanager',
        password: '1',
      },
      passwordFieldType: 'password',
      visibility: false,
      visibilityIcon: 'visibility_off',
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      userRole: 'user/role',
    }),
  },
  methods: {
    ...mapActions('user', ['login']),

    async tryLogin() {
      let login = await this.login({
        login: this.authData.login,
        password: this.authData.password
      });

      if(login.access_token){
        // this.authData.login = '';
        // this.authData.password = '';
        // this.authData.errorText = '';
        await this.$store.dispatch('loadStore')

        this.$router.push({ name: `${this.userRole}.index` });
      }
      else{
        // this.authData.errorText = login.errors.join(',');
      }
    },



    // login() {
    //   this.loading = true;
    //   store.dispatch("login", this.user)
    //     .then(() => {
    //       this.loading = false;
    //       this.$router.push('/' + store.getters.role);
    //     })
    //     .catch((err) => {           //TODO не работает catch на логине
    //       this.loading = false;
    //       // errorMsg.value = err.response.data.error;
    //     });
    // },
    switchVisibility() {
      this.visibility = !this.visibility
      this.passwordFieldType = this.visibility ? 'text' : 'password'
      this.visibilityIcon =  this.visibility ? 'visibility' : 'visibility_off'
    }
  }
}
</script>
<style>
.q-card {
  width: 360px;
}
</style>
