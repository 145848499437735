<template>
  <Input
    label="Телефон"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"

    mask="+7 (###) ### - ####"
    fill-mask-="_"
    unmasked-value

    :rules="rules"
  />

</template>

<script>
import Input from '@/components/ui/Input'

export default {
  name: 'InputPhone',
  components: {
    Input
  },
  props: {
    modelValue: {},
    rules: {
      type: Array,
      default: [ val => val && val.length > 0 || 'Введите телефон']
    },
  }
}
</script>
