<template>
  <q-dialog
    :modelValue="modalCreating.isOpen"
    @update:modelValue="modalCreating.isOpen = $event"
  >
    <q-card style="width: 900px; max-width: 90vw;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Создание встречи</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-form @submit="create">
        <q-card-section>
          <div class="row q-col-gutter-x-md">
            <select-deal v-model="modalCreating.data.deal_id" />

            <Select label="Должность"
              v-model="modalCreating.data.role_slug"
              :options="[
                {id: 'jurist', name: 'Юрист'},
                {id: 'agent', name: 'Агент'}
              ]"
              :clearable="false"
            />

            <select-employee v-model="modalCreating.data.user_id"
              :role="modalCreating.data.role_slug"
            />

            <date-time-picker label="Время начала"
              v-model="modalCreating.data.start_at"
            />

            <date-time-picker label="Время окончания"
              v-model="modalCreating.data.end_at"
            />
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <btn-close />
          <btn-save />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";
import Select from '@/components/ui/Select'

export default {
  components: {Select},

  computed: mapState({
    'modalCreating': state => state.meets.modals.creating,
  }),

  methods: {
    ...mapActions('meets', [
      'create'
    ]),
  },
}
</script>
