import { createStore } from 'vuex'
import * as dealsApi from '@/api/deals';

import user from '@/store/user'
import minicalendar from '@/store/minicalendar'

import deals from '@/store/deals'
import clients from '@/store/clients'
import contracts from '@/store/contracts'
import payments from '@/store/payments'
import meets from '@/store/meets'

import managers from '@/store/managers'
import submanagers from '@/store/submanagers'
import jurists from '@/store/jurists'
import agents from '@/store/agents'
import {getLists} from "@/api/deals";

export default createStore({
  state: {
    // minicaliendar: {
    //   date: '2021-06-20',
    // },
    // date: '2024-06-20',

    lists: {
      stages: [],
      subjects: [],
      themes: [],
      completeds: [],
      actResults: [],
      appeals: [],
      statuses: [],
      results: [],

      managers: [],
      submanagers: [],
      jurists: [],
      agents: [],
      paymentTypes: [],
    },
    listsLoaded: false,
  },
  actions: {

    async loadLists({state}){
      let dealLists = await dealsApi.getLists()
      state.lists.stages = dealLists.stages;
      state.lists.subjects = dealLists.subjects;
      state.lists.themes = dealLists.themes;
      state.lists.completeds = dealLists.completeds;
      state.lists.actResults = dealLists.act_results;
      state.lists.appeals = dealLists.appeals;
      state.lists.statuses = dealLists.statuses;
      state.lists.results = dealLists.results;
      console.log('dealLists')

      let managers = await http.get('/managers/list')
      state.lists.managers = managers.data;
      console.log('managers')

      let submanagers = await http.get('/submanagers/list')
      state.lists.submanagers = submanagers.data;
      console.log('submanagers')

      let jurists = await http.get('/jurists/list')
      state.lists.jurists = jurists.data;
      console.log('jurists')

      let agents = await http.get('/agents/list')
      state.lists.agents = agents.data;
      console.log('agents')

      let paymentTypes = await http.get('/payments/types')
      state.lists.paymentTypes = paymentTypes.data;
      console.log('paymentTypes')

      state.listsLoaded = true

      return true

      // let deals = await dealsApi.all();
      // commit('setItems', deals);
      // return deals;
    },

    // loadMinicalendar({state}) {
    //   state.miniCaliendar.date = '2024-06-20'
    // },
    // changeMinicalendar({state}, route) {
    //   if (route === 'submanager.index') {
    //     state.minicaliendar.date = value
    //   }
    //   if (this.$route.name === 'submanager.calendar') {
    //     state.minicaliendar.date = value
    //   }
    // }

    loadStore({state, dispatch}) {
      dispatch('loadLists')
      dispatch('minicalendar/load')
    }

    // async load({ commit }){
    //   let deals = await dealsApi.all();
    //   commit('setItems', deals);
    //   return deals;
    // }
  },
  modules: {
    user,
    minicalendar,

    deals,
    clients,
    contracts,
    payments,
    meets,

    managers,
    submanagers,
    jurists,
    agents,
  }
})
