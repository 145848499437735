import http from '@/services/http';

export async function getAll({page, filter, date}){
  let { data } = await http.get('/deals',{params: {page: page, ...filter, date: date}});
  return data;
}

export async function getOne(id){
  let { data } = await http.get(`/deals/${id}`);
  return data;
}

export async function create(item){
  let { data } = await http.post('/deals', item);
  return data;
}

export async function update(item){
  let { data } = await http.put(`/deals/${item.id}`, item);
  return data;
}

export async function remove(id){
  let { data } = await http.delete(`/deals/${id}`);
  return data;
}

export async function getLists(){
  let { data } = await http.get('/deals/lists');
  return data;
}


// export default http => ({
//   async all(){
//     let { data } = await http.get('products.php');
//     return data;
//   },
//   async one(id){
//     let { data } = await http.get('ratings.php', {
//       params: { id },
//     });
//     return data;
//   },
//   async add(item){
//     let { data } = await http.post('cart.php', { item });
//     return data;
//   },
//
//
//
//   // async rating(id){
//   //   let { data } = await http.get('ratings.php', {
//   //     params: { id },
//   //     errorAlert: { text: 'при получении рейтинга товара' }
//   //   });
//   //   return data;
//   // },
//   // async mark(id, mark){
//   //   let { data } = await http.put('ratings.php', { id, mark }, {
//   //     errorAlert: { text: 'при оценке товара' }
//   //   });
//   //   return data;
//   // }
// })






// http.post(`/deals`, this.modal.data)
//   .then(response => {
//     this.modal.isShow = false
//     this.$emit("created", response.data)
//   })
//   .catch(error => {
//     console.log(error)
//   })

// getDeals({ pagination, filter }) {
//   this.loading = true
//
//   const number = filter.number ?? '';
//   const juristId = filter.jurist_id ?? '';
//   const agentId = filter.agent_id ?? '';
//
//   http.get('/deals'
//     + '?number=' + number
//     + '&jurist_id=' + juristId
//     + '&agent_id=' + agentId,
//     {params: {
//         page: pagination.page,
//       }}).then(response => {
//     console.log(response.data.data);
//     this.rows = response.data.data;
//     // pagination
//     this.serverPagination.rowsNumber = response.data.total
//     this.serverPagination.rowsPerPage = response.data.per_page
//     this.serverPagination.page = response.data.current_page
//     this.loading = false
//   }).catch(error => {
//     console.log(error);
//     this.errored = true;
//     this.loading = false
//   })
// },
