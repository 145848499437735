<template>
  <q-input
    :label="label + ':'"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"

    filled
    type="textarea"
    class="col-12"

    :rules="rules"
    :error="error"
    :error-message="errorMessage"
  />
</template>

<script>

export default {
  name: 'Textarea',
  props: {
    modelValue: {},
    label: {
      type: String,
    },
    rules: {
      type: Array,
      // default: [ val => val && val.length > 0 || 'Введите комментарий']
      default: []
    },
    error: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
    },
  },
}
</script>
