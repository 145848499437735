<template>
  <q-dialog
    :modelValue="modalRemoving.isOpen"
    @update:modelValue="modalRemoving.isOpen = $event"
  >
    <q-card>
      <q-card-section class="row items-center bg-primary text-white">
        <!--          <q-avatar icon="warning" text-color="warning" />-->
        <q-avatar icon="warning" />
        <span class="q-ml-sm">
          Удалить встречу?
        </span>
      </q-card-section>

      <q-card-actions align="right">
        <btn-close />
        <btn-continue @click="remove" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  computed: mapState({
    'modalRemoving': state => state.meets.modals.removing,
  }),

  methods: {
    ...mapActions('meets', [
      'remove'
    ]),
  },
}
</script>
