<template>
  <Input
    label="Пароль"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    :type="isPassword ? 'password' : 'text'"


    :rules="rules"
  >
    <template v-slot:append>
      <q-icon
        :name="isPassword ? 'visibility_off' : 'visibility'"
        class="cursor-pointer"
        @click="isPassword = !isPassword"
      />
    </template>
  </Input>
</template>

<script>
import Input from '@/components/ui/Input'

export default {
  name: 'InputPassword',
  components: {
    Input
  },
  props: {
    modelValue: {
      // type: Number,
      // required: true,
    },
    rules: {
      type: Array,
      default: [ val => val && val.length > 0 || 'Введите пароль']
    },
  },
  data() {
    return {
      isPassword: true
    }
  }
}
</script>
