<template>
  <q-dialog
    :modelValue="modalCreating.isOpen"
    @update:modelValue="modalCreating.isOpen = $event"
  >
    <q-card style="width: 900px; max-width: 90vw;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Создание договора</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>
      <q-form @submit="create">
        <q-card-section>
          <div class="row q-col-gutter-x-md">
            <slot />
            <select-deal v-model="modalCreating.data.deal_id" />
            <!--    <select-client v-model="modalCreating.data.client_id" />-->
            <input-number v-model="modalCreating.data.number" />
            <input-pin v-model="modalCreating.data.pin" />
            <input-sum v-model="modalCreating.data.sum" />
            <input-prepay v-model="modalCreating.data.prepay" />
            <input-balance v-model="modalCreating.data.balance" />
            <date-picker label="Дата доплаты" v-model="modalCreating.data.pay_date" :rules="[ val => val && val.length > 0 || 'Выберите дату доплаты']" />
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <btn-save />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  computed: mapState({
    'modalCreating': state => state.contracts.modals.creating,
  }),

  methods: {
    ...mapActions('contracts', [
      'create'
    ]),
  },
}
</script>
