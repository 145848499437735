<template>
  <Textarea
    label="Комментарий"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"

    :rules="rules"
  />
</template>

<script>
import Textarea from "@/components/ui/Textarea";

export default {
  name: 'TextComment',
  components: {Textarea},
  props: {
    modelValue: {},
    rules: {
      type: Array,
      default: []
    },
  }
}
</script>
