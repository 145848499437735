<template>
  <q-dialog
    :modelValue="modalUpdating.isOpen"
    @update:modelValue="modalUpdating.isOpen = $event"
  >
    <q-card style="width: 900px; max-width: 90vw;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Редактирование зама</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-form @submit="update">
        <q-card-section>
          <div class="row q-col-gutter-x-md">
            <input-name v-model="modalUpdating.data.name"/>
            <input-surname v-model="modalUpdating.data.surname"/>
            <input-patronymic v-model="modalUpdating.data.patronymic" :rules="[]"/>
            <input-phone v-model="modalUpdating.data.phone" />

            <input-login v-model="modalUpdating.data.user.login"/>
            <input-password v-model="modalUpdating.data.user.password"/>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <btn-close />
          <btn-save />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  computed: mapState({
    'modalUpdating': state => state.submanagers.modals.updating,
  }),

  methods: {
    ...mapActions('submanagers', [
      'update'
    ]),
  },
}
</script>
