import http from '@/services/http';

export async function getAll({page, filter}){
  let { data } = await http.get('/agents',{params: {page: page, ...filter}});
  return data;
}

export async function getOne(id){
  let { data } = await http.get(`/agents/${id}`);
  return data;
}

export async function create(item){
  let { data } = await http.post('/agents', item);
  return data;
}

export async function update(item){
  let { data } = await http.put(`/agents/${item.user_id}`, item);
  return data;
}

export async function remove(id){
  let { data } = await http.delete(`/agents/${id}`);
  return data;
}

