import http from '@/services/http';

export async function getAll({page, filter}){
  let { data } = await http.get('/contracts',{params: {page: page, ...filter}});
  return data;
}

export async function getOne(id){
  let { data } = await http.get(`/contracts/${id}`);
  return data;
}

export async function create(item){
  let { data } = await http.post('/contracts', item);
  return data;
}

export async function update(item){
  let { data } = await http.put(`/contracts/${item.id}`, item);
  return data;
}

export async function remove(id){
  let { data } = await http.delete(`/contracts/${id}`);
  return data;
}

