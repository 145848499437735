<template>
  <q-table title="Клиенты"
    @request="getAll"
    :columns="columns"
    :rows="rows"
    v-model:pagination="$store.state.clients.table.pagination"
    v-model:pagination-="pagination"
    :pagination-="pagination"
    @update:pagination-="changePagination($event)"
    :filter="filter"
    :loading="loading"
    separator="cell"
    class="my-sticky-header-table"
    row-key="id"
    dense
    no-results-label="Клиентов не найдено"
    :rows-per-page-options="[]"
  >
    <template v-slot:top-right>
      <slot name="top"></slot>
      <q-input label="Искать клиента..."
        clearable
        dense
        filled
        v-model="filter.search"
        class="q-mx-md"
      >
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
      <btn-square icon="add" @click="openModalCreating">
        Создать клиента
      </btn-square>
    </template>
    <template v-slot:body-cell-name="props">
      <q-td :props="props">
        <!-- <q-btn color="primary" icon="check" label="OK" @click="onClick" /> -->
        <!-- <q-btn to="/start/pick-quasar-flavour" :label="props.value" color="purple" /> -->
        <q-btn
          flat
          :label="props.value"
          no-caps
          @click="openModalUpdating(props.row)"
        />
      </q-td>
    </template>
    <template v-slot:body-cell-actions="props">
      <q-td :props="props"  class="text-right">
        <!-- <q-btn color="primary" icon="check" label="OK" @click="onClick" /> -->
        <!-- <q-btn to="/start/pick-quasar-flavour" :label="props.value" color="purple" /> -->
<!--        <q-btn-->
<!--          square-->
<!--          dense-->
<!--          color="red"-->
<!--          icon="close"-->
<!--          @click="remove(props.row.id)"-->
<!--        />-->
        <q-btn
          dense
          flat
          color="red"
          icon="delete_forever"
          @click="openModalRemoving(props.row)"
        />
      </q-td>
    </template>
  </q-table>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  props: {
    columns: { type: Array, required: true },
  },
  computed: mapState({
    'rows': state => state.clients.table.data,
    'pagination': state => state.clients.table.pagination,
    'filter': state => state.clients.table.filter,
    'loading': state => state.clients.table.loading,
  }),
  mounted() {
    this.getAll({
      pagination: this.pagination,
      filter: this.filter
    })
  },
  methods: {
    ...mapActions('clients', [
        'openModalCreating',
        'openModalUpdating',
        'openModalRemoving',
        // 'changePagination',
        'getAll',
        'remove'
    ]),
  }
}
</script>

<style lang="scss">
//.my-sticky-header-table {
//  /* height or max-height is important */
//  height: 600px;
//
//  .q-table__top,
//  .q-table__bottom,
//  thead tr:first-child th {
//    /* bg color is important for th; just specify one */
//    background-color: #eee;
//  }
//  thead tr th {
//    position: sticky;
//    z-index: 1;
//  }
//  thead tr:first-child th {
//    top: 0;
//  }
//  /* this is when the loading indicator appears */
//  &.q-table--loading thead tr:last-child th {
//    /* height of all previous header rows */
//    //top: 48px;
//  }
//}

</style>
