import http from '@/services/http';

export async function getAll({page, filter}){
  let { data } = await http.get('/meets',{params: {page: page, ...filter}});
  return data;
}

export async function getOne(id){
  let { data } = await http.get(`/meets/${id}`);
  return data;
}

export async function create(item){
  let { data } = await http.post('/meets', item);
  return data;
}

export async function update(id, item){
  let { data } = await http.put(`/meets/${id}`, item);
  return data;
}

export async function remove(id){
  let { data } = await http.delete(`/meets/${id}`);
  return data;
}

export async function getForCalendar(date){
  let { data } = await http.get(`/meets/calendar/${date}`);
  return data;
}
