import * as paymentsApi from '@/api/payments'
import Notify from 'quasar/src/plugins/Notify.js';;

export default {
  namespaced: true,
  state: {
    table: {
      data: [],
      filter: {},
      pagination: {
        page: 1,
        rowsPerPage: 15,
        rowsNumber: 0
      },
      loading: false,
    },
    modals: {
      creating: {
        isOpen: false,
        data: {
          stage_id: 1
        }
      },
      showing: {
        isOpen: false,
        data: {}
      },
      updating: {
        isOpen: false,
        data: {}
      },
      removing: {
        isOpen: false,
        data: {}
      },
    },
  },
  actions: {
    openModalCreating({state}) {
      state.modals.creating.isOpen = true
    },

    openModalShowing({state}, item) {
      state.modals.showing.isOpen = true
      state.modals.showing.data = item
    },

    openModalUpdating({state}, item) { //TODO значение не должно меняться в таблице
      state.modals.updating.isOpen = true
      state.modals.updating.data = item
    },

    openModalRemoving({state}, item) {
      state.modals.removing.isOpen = true
      state.modals.removing.data = item
    },

    async getAll({state}, { pagination, filter }) {
      state.table.loading = true

      let res = await paymentsApi.getAll({
        page: pagination.page,
        filter: filter,
      })

      state.table.data = res.data

      state.table.pagination.page = res.current_page
      state.table.pagination.rowsPerPage = res.per_page
      state.table.pagination.rowsNumber = res.total

      state.table.loading = false

      // console.log(res)
    },

    async create({state}) {
      let createdPayment = await paymentsApi.create(state.modals.creating.data)

      state.table.data.unshift(createdPayment)

      state.modals.creating.isOpen = false
      state.modals.creating.data = {
        stage_id: 1
      }

      Notify.create({
        message: `Платеж №${ createdPayment.id } создан`,
        color: 'purple'
      })
    },

    async update({state}) {
      let updatedPayment = await paymentsApi.update(state.modals.updating.data)

      if (updatedPayment !== '') {
        state.table.data.map(payment => {
          if (payment.id === updatedPayment.id) {
            for (let key in payment) {
              payment[key] = updatedPayment[key];
            }
          }
        })
      }

      state.modals.updating.isOpen = false
      state.modals.updating.data = {}

      Notify.create({
        message: `Платеж №${ updatedPayment.id } изменен`,
        color: 'purple'
      })
    },

    async remove({state}) {
      let removedPayment = await paymentsApi.remove(state.modals.removing.data.id)

      // state.table.data.splice(state.modals.removing.data.index, 1)
      state.table.data = state.table.data.filter(payment => payment.id !== removedPayment.id)

      state.modals.removing.isOpen = false
      state.modals.removing.data = {}

      Notify.create({
        message: `Платеж №${ removedPayment.id } удален`,
        color: 'purple'
      })
    },


  },



  // // getters: {
  // //   showModalCreatePayment: state => state.modals.createPayment.isOpen,
  // //   createblePayment: state => state.modals.createPayment.data,
  // // },
  // mutations: {
  //   setItems(state, items){
  //     state.items = items;
  //   }
  // },
  // actions: {
  //   openModalPaymentCreating({state}) {
  //     state.modals.creating.isOpen = true
  //   },
  //
  //   openModalPaymentUpdating({state}, payment) {
  //     state.modals.updating.isOpen = true
  //     state.modals.updating.data = payment
  //   },
  //
  //   // closeModalPaymentCreating({state}){
  //   //   state.modals.creatingPayment.isOpen = false
  //   //   state.modals.creatingPayment.data = {}
  //   // },
  // }
}
