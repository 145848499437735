import * as contractsApi from "@/api/contracts";
import Notify from 'quasar/src/plugins/Notify.js';;

export default {
  namespaced: true,
  state: {
    table: {
      data: [],
      filter: {},
      pagination: {
        page: 1,
        rowsPerPage: 15,
        rowsNumber: 0
      },
      loading: false,
    },
    modals: {
      creating: {
        isOpen: false,
        data: {}
      },
      updating: {
        isOpen: false,
        data: {}
      },
      removing: {
        isOpen: false,
        data: {}
      },
    },
  },
  actions: {
    openModalCreating({state}) {
      state.modals.creating.isOpen = true
    },

    openModalUpdating({state}, item) { //TODO значение не должно меняться в таблице
      state.modals.updating.isOpen = true
      state.modals.updating.data = item
    },

    openModalRemoving({state}, item) {
      state.modals.removing.isOpen = true
      state.modals.removing.data = item
    },

    async getAll({state}, { pagination, filter }) {
      state.table.loading = true

      let res = await contractsApi.getAll({
        page: pagination.page,
        filter: filter,
      })

      state.table.data = res.data

      state.table.pagination.page = res.current_page
      state.table.pagination.rowsPerPage = res.per_page
      state.table.pagination.rowsNumber = res.total

      state.table.loading = false

      console.log(res)
    },

    async create({state}) {
      let createdContract = await contractsApi.create(state.modals.creating.data)

      state.table.data.unshift(createdContract)

      state.modals.creating.isOpen = false
      state.modals.creating.data = {}

      Notify.create({
        message: `Договор №${ createdContract.number } создан`,
        color: 'purple'
      })
    },

    async update({state}) {
      let updatedContract = await contractsApi.update(state.modals.updating.data)

      if (updatedContract !== '') {
        state.table.data.map(contract => {
          if (contract.id === updatedContract.id) {
            for (let key in contract) {
              contract[key] = updatedContract[key];
            }
          }
        })
      }

      state.modals.updating.isOpen = false
      state.modals.updating.data = {}

      Notify.create({
        message: `Договор №${ updatedContract.number } изменен`,
        color: 'purple'
      })
    },

    async remove({state}) {
      let removedContract = await contractsApi.remove(state.modals.removing.data.id)

      // state.table.data.splice(state.modals.removing.data.index, 1)
      state.table.data = state.table.data.filter(contract => contract.id !== removedContract.id)

      state.modals.removing.isOpen = false
      state.modals.removing.data = {}

      Notify.create({
        message: `Договор №${ removedContract.number } удален`,
        color: 'purple'
      })
    },
  },
}
