<template>
  <q-btn
    class="q-ml-sm"
    round
    size="sm"
    color="primary"
  />
</template>

<script>
export default {
  name: 'BtnRound',
}
</script>
