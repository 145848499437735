
import 'quasar/dist/quasar.css'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/ionicons-v4/ionicons-v4.css'
import '@quasar/extras/mdi-v4/mdi-v4.css'
import '@quasar/extras/eva-icons/eva-icons.css'
import lang from 'quasar/lang/ru'
import Notify from 'quasar/src/plugins/Notify.js';
// import  { QCalendarDay }  from '@quasar/quasar-ui-qcalendar'
// import '@quasar/quasar-ui-qcalendar/dist/QCalendarDay.min.css'

// To be used on app.use(Quasar, { ... })
export default {
  plugins: {
    Notify,
    // QCalendarDay
  },
  config: {
    notify: { /* look at QuasarConfOptions from the API card */ }
  },
  lang: lang
}
