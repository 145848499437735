<template>
  <q-select
    :label="label + ':'"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"

    clearable
    emit-value
    map-options

    :options="options"
    :option-value="optionValue"
    :option-label="optionLabel"
    filled
    class="col-xs-12 col-sm-6 col-md-4"

    :rules="rules"
    :error="error"
    :error-message="errorMessage"
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      // type: Number,
      // required: true,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
    },
    optionValue: {
      type: String,
      default: 'id',
    },
    optionLabel: {
      type: String,
      default: 'name',
    },
    rules: {
      type: Array,
    },
    error: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
    },

  },

  // props: [
  //   'modelValue',
  //   'label',
  //
  //   'options',
  //   'value-name',
  //   'option-name',
  // ],
}
</script>
