import axios from 'axios'
import { getAccessToken, setTokens, cleanTokensData } from '@/services/tokens'
import router from '@/router'
import store from '@/store'

const instance = axios.create({
  baseURL: `https://api.uragents01.ru/api`,
  // baseURL: 'http://uragents7/api/',
  // timeout: 10000,
  // withCredentials: true
})

instance.interceptors.request.use(addAccessToken);

instance.interceptors.response.use(
  r => r,
  async error => {
    if(error.response.status !== 401){
      return Promise.reject(error); // ошибка, не связанная с авторизацией
    }

    cleanTokensData();
    let response = await instance.get('auth/refresh');

    if(!response.data.res){
      // clean user data & tokens
      store.dispatch('user/clean');

      router.push({name: 'login'}).then(() => {
        //location.reload(); // options vs 33 idea
      });
      return // ?

      // return Promise.reject(error); // прокидываем 401 код дальше
    }

    setTokens(response.data.accessToken);
    return axios(addAccessToken(error.config));
  }
);

export default instance;

function addAccessToken(request) {
  let token = getAccessToken();

  if(token !== null) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
}




