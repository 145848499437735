<template>
  <q-dialog
    :modelValue="modalShowing.isOpen"
    @update:modelValue="modalShowing.isOpen = $event"
  >
    <q-card style="width: 900px; max-width: 90vw;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Просмотр агента</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>
      <q-card-section>
        <div class="row q-col-gutter-x-md">
<!--          <select-contract v-model="modalCreating.data.contract_id" />-->
<!--          <select-payment-type v-model="modalCreating.data.type_slug" />-->
<!--          <input-sum v-model="modalCreating.data.sum" />-->
<!--          <input-transport v-model="modalCreating.data.transport" />-->
        </div>
      </q-card-section>
      <q-card-actions align="right">
        <btn-close />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  computed: mapState({
    'modalShowing': state => state.agents.modals.showing,
  }),
}
</script>
